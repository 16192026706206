<template>
  <div>
    <div class="container">
      <div class="d-flex justify-space-between mt-5">
        <div class="col-6">
          <div class="card withdraw-card" style="border-radius: 10px 10px 10px 10px;">
            <div class="card-header" style="border-bottom: 0px;background: #EAEAEA;border-radius: 10px 10px 0px 0px;height:50px;">
              <span class="card-title h6 mt-2 " style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 14px;color: #545454;">Select Withdrawal Method</span>
            </div>

            <div class="card-body" style="padding: 0; min-height: 400px">
              <div style="overflow-y: auto; max-height: 400px;">
                <div v-for="(method, i) in paymentMethods" 
                  v-bind:key="method.brand">
                  <div class="payment-method" style="height: 70px;">
                    <div class=" d-flex my-auto">
                      <div class="d-flex my-auto">
                        <div class="logo px-3 mt-3">
                          <img :src="method.logo" alt="">
                        </div>
                        <div class="my-auto">
                          <div class="f-roboto f-12" style="">
                            <div style="margin-top:15px;font-family: Roboto;font-style: normal;font-weight: 500;font-size: 12px;color: #545454;">
                              {{ method.brand }}
                            </div>
                          </div>
                          <div style="margin-top:5px;font-family: Roboto;font-style: normal;font-weight: normal;font-size: 10px;color: #8B8B8B;" v-html="method.tagline">
                           
                          </div>
                        </div>
                      </div>
                      <div class="ml-auto d-flex mt-3">
                        <div class="my-auto mr-3">
                          <div v-if="method.sandbox" class="badge badge-warning ">Sandbox Mode</div>
                        </div>
                        <div class="my-auto mr-3">
                          <div>

                            <button v-if="method.todo" class="btn btn-primary withdraw-button" disabled>
                              Coming soon
                            </button>
                            <button v-else-if="method.id != selectedMethodId" @click="select(method.id)" class="btn btn-primary withdraw-button">
                              SELECT
                            </button>
                            <button v-else-if="method.id == selectedMethodId" @click="select(method.id)" class="btn btn-success withdraw-button">
                              SELECTED
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="i != paymentMethods.length-1" class="mt-1 mb-1 hr"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card withdraw-card" style="border-radius: 10px 10px 10px 10px;">
            <div class="card-header" style="border-bottom: 0px;background: #EAEAEA;border-radius: 10px 10px 0px 0px;height:50px;">
              <span class="card-title h6 mt-2 " style="font-family: Roboto;font-style: normal;font-weight: 900;font-size: 14px;color: #545454;">Select Withdrawal Amount</span>
            </div>

            <div class="card-body h-100" style="padding: 0; min-height: 400px; max-height: 400px; height: 400px">
              <div class="h-100" style="overflow-y: auto; min-height: 400px; max-height: 400px; height: 400px;">
                <component
                  v-if="selectedMethod"
                  v-on:withdraw="withdraw"
                  ref="activeMethod"
                  :is="selectedMethod.component"
                  @on:falureLoading="selectPrevious"
                ></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

import numeral from 'numeral';
import api from '../api';

export default {
  data() {
    return {
      availableBalance: null,
      amount: null,
      selectedMethodId: "",
      paymentMethods: [
        { 
          id: 'paypal', 
          logo: require('../assets/paypal.png'), 
          brand: "PayPal", 
          method: "PayPal",
          tagline: "Instant delivery. $0.25 fee.", 
          sandbox: false,
          component: () => import('@/components/WithdrawMethods/PayPal.vue'),
        },
        {
          id: 'venmo', 
          logo: require('../assets/venmoLogo.png'), 
          method: "Venmo",
          brand: "Venmo",
          tagline: "Instant delivery. $0.25 fee.",
          sandbox: false,
          component: () => import('@/components/WithdrawMethods/Venmo.vue')
        },
        // {
        //   id: 'dwolla',
        //   logo: require('../assets/ach.png'),
        //   method: "Dwolla",
        //   brand: "ACH",
        //   tagline: "USA only. 0.5% fee. 1-3 days delivery",
        //   sandbox: true,
        //   component: () => import('@/components/WithdrawMethods/Dwolla.vue')
        // },
        {
          id: 'coinbase',
          logo: require("../assets/coinbase.png"),
          method: "Coinbase",
          brand: "Coinbase",
          tagline: "<b>Recommended:</b> instant delivery. 0% fee",
          sandbox: false,
          component: () => import('@/components/WithdrawMethods/Coinbase.vue')
        },
        // {
        //   id: 'skrill',
        //   logo: require('../assets/skrill.png'),
        //   method: "Skrill",
        //   brand: "Skrill",
        //   tagline: "Withdraw with Skrill",
        //   todo: true,
        //   component: () => import('@/components/WithdrawMethods/Skrill.vue')
        // },
        // {
        //   id: 'tangocard',
        //   logo: require('../assets/tangocard.svg'),
        //   method: "Tangocard",
        //   brand: "Tangocard",
        //   tagline: "Withdraw with TangoCard",
        //   todo: true,
        //   component: () => import('@/components/WithdrawMethods/Tangocard.vue')
        // },
        // {
        //   id: 'transferwise',
        //   logo: require('../assets/transferwise.jpeg'),
        //   method: "Transferwise",
        //   brand: "Transferwise",
        //   tagline: "Withdraw with Transferwise",
        //   todo: true,
        //   component: () => import('@/components/WithdrawMethods/Transferwise.vue')
        // },
      ]
    }
  },
  async mounted() {
    this.select('paypal')
    // this.select('venmo')
  },
  computed: {
    selectedMethod() {
      return this.paymentMethods.find(x => x.id == this.selectedMethodId);
    },
    amountFormatted() {
      return numeral(this.amount).format('$0,0.00');
    },
    balance: function() { return "$" + (this.$store.getters['auth/user'].balance / 100).toFixed(2) },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    select(paymentMethodId) {
      this.previousPaymentMethodId = this.selectedMethodId;
      this.selectedMethodId = paymentMethodId;
    },
    selectPrevious() {
      this.selectedMethodId = this.previousPaymentMethodId;
    },
    async withdraw(paymentData) {
      console.log("Got withdraw request");
      try {
        this.$refs.activeMethod.loading = true;
        // Amount in USD.
        let selectedMethod = this.selectedMethod;

        if (this.user.state == "HARD_BANNED") {
          this.$refs.activeMethod.loading = false;
          return Swal.fire('Account Disabled', "Please contact support", 'danger');
        }

        let amount = paymentData.amount;

        if (!amount) {
          console.log("test")
          this.$refs.activeMethod.loading = false;
          return Swal.fire('Validation Error', "Please enter an amount first!", 'warning');
        }

        if (!selectedMethod) {
          this.$refs.activeMethod.loading = false;
          return Swal.fire('Validation Error', "Please select a method first!", 'warning');
        }

        let balance = this.user.balance / 100

        if (amount > balance || amount > this.user.salesBalance / 100) {
          this.$refs.activeMethod.loading = false;
          console.log("Test", amount, balance)
          return Swal.fire('Validation Error', "You can't withdraw this much!", 'warning');
        }
        
        let token;
        if (this.user.twoFactorEnabled) {
          let response = await Swal.fire({
            title: "Confirm Two Factor Code",
            html: "Please enter your two factor code displayed on your authenticator",
            input: "text",
            icon: "info",
            inputValidator: (value) => {
              if (!value) return 'You need to write something!'
              if ((value + '').length != 6) return 'Your code must be 6 digits.';
              if (isNaN(parseInt(value))) return 'Your code must be 6 digits.';
            }
          });
          token = response.value;
        }

        try {
          let response = await api.withdrawFunds(amount, selectedMethod.method, paymentData, token);
          this.$router.push(`/success/${response.data.transactionId}`);
          // Swal.fire('Pending process', `Thanks for submitting your withdrawal via ${selectedMethod.method}. Your transaction has been scheduled and you can see its process in the <a href="#0">My Activity</a> page.`, 'success');
        } catch (e) {
          // TODO Process things like insufficent balance correctly.
          console.log("TEst", e);
          console.log(e);
          if (e.response && e.response.data.errors) {
            this.$refs.activeMethod.loading = false;
            return Swal.fire('Error', e.response.data.errors[0], 'error');
          }
          Swal.fire('Error', "An issue occured whilst processing your request, please try again later. If the problem persists please contact our support team.", 'error');
        }
        // debugger;
        this.$refs.activeMethod.loading = false;
      } catch (e) {
        Swal.fire('Error', 'An issue occured on our end, please try again later.', 'error');
        console.log(e);
        // debugger;
        this.$refs.activeMethod.loading = false;
        throw e;
      }

      // Process withdrawal.
    },
  },
  watch: {

  }
}
</script>

<style lang="scss">
  .withdraw-textbox {
    background-color: #E6E6E6 !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .withdraw-max-button {
    border: 1px solid black !important;
  }
  .withdraw-account-textbox {
    max-width: 200px !important;
    border-radius: 50px !important;
    background-color: #E6E6E6 !important;
    font-family: 'Roboto';
    font-size: 12px;
    border: 0 !important;
  }
  .withdraw-button {
    width: 170px !important;

    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    color: #FFFFFF;
    width:110px!important;
    padding-left:0px;
    padding-right:0;
    text-align:center;
  }

  .conntect-button {
    background-color:#B0B0B0 !important;
  }

  .withdraw-card {
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url('../assets/bg.png');
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    >.card-body {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .logo {
    width: 100px;
    height: 40px;
    img {
      height: 40px;
      width: 60px;
      object-fit: contain;
    }
  }
</style>